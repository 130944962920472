import { createSlice } from "@reduxjs/toolkit";
import { Attachments, MedicalData } from "../../types/medicalData";

export interface IState {
  patientId: string | undefined;
  allMedicalData: MedicalData | undefined;
  journals: Attachments[];
  medications: Attachments[];
  labResults: Attachments[];
  warnings: Attachments[];
  otherDocuments: Attachments[];
  zoomLevel: number;
}

const initialState: IState = {
  patientId: undefined,
  allMedicalData: undefined,
  journals: [],
  medications: [],
  labResults: [],
  warnings: [],
  otherDocuments: [],
  zoomLevel: 1.2,
};

export const TYPE_JOURNAL = "+journal";
export const TYPE_MEDICAL = "+medication";
export const TYPE_LAB = "+lab";
export const TYPE_WARNING = "+warning";
export const TYPE_DOCUMENT = "+document";

export const getDocumentsFromType = (
  type: string,
  attachments: Attachments[]
): Attachments[] => {
  const res = attachments?.filter((attachment: Attachments) => {
    return attachment.contentType.includes(type);
  });
  return res ? res : [];
};

export const medicalDataState = createSlice({
  name: "medicalDataState",
  initialState,
  reducers: {
    setPatientId: (state, action) => {
      const { id } = action.payload;
      state.patientId = id;
    },
    setMedicalData: (state, action) => {
      const { data } = action.payload;
      state.allMedicalData = data;
      state.journals = getDocumentsFromType(TYPE_JOURNAL, data.attachments);
      state.medications = getDocumentsFromType(TYPE_MEDICAL, data.attachments);
      state.labResults = getDocumentsFromType(TYPE_LAB, data.attachments);
      state.warnings = getDocumentsFromType(TYPE_WARNING, data.attachments);
      state.otherDocuments = getDocumentsFromType(
        TYPE_DOCUMENT,
        data.attachments
      );
    },
    setZoom: (state, action) => {
      const { zoom } = action.payload;
      state.zoomLevel = zoom;
    },
  },
});
