import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { RootState } from "../../App";
import "./Navigation.scss";

const Navigation: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const journalData = useSelector(
    (state: RootState) => state.medicalDataState.journals
  );
  const medicationData = useSelector(
    (state: RootState) => state.medicalDataState.medications
  );
  const labData = useSelector(
    (state: RootState) => state.medicalDataState.labResults
  );
  const warningData = useSelector(
    (state: RootState) => state.medicalDataState.warnings
  );
  const documentData = useSelector(
    (state: RootState) => state.medicalDataState.otherDocuments
  );

  let query = useQuery();
  let tabName = query.get("tab");

  return (
    <nav className="navigation" id="top-of-page">
      <ul>
        <li>
          <NavLink
            to={`/${id}?tab=journal`}
            isActive={() => {
              return (
                tabName === null || tabName === "" || tabName === "journal"
              );
            }}
          >
            {t("navigation.journal")}{" "}
            <span className="counter">{journalData.length}</span>
          </NavLink>
        </li>
        {medicationData.length > 0 &&
        <li>
          <NavLink
            to={`/${id}?tab=medication`}
            isActive={() => {
              return tabName === "medication";
            }}
          >
            {t("navigation.medication")}{" "}
            <span className="counter">{medicationData.length}</span>
          </NavLink>
        </li>
        }
        {labData.length > 0 &&
        <li>
          <NavLink
            to={`/${id}?tab=lab`}
            isActive={() => {
              return tabName === "lab";
            }}
          >
            {t("navigation.labList")}{" "}
            <span className="counter">{labData.length}</span>
          </NavLink>
        </li>
        }
        {warningData.length > 0 &&
        <li>
          <NavLink
            to={`/${id}?tab=warning`}
            isActive={() => {
              return tabName === "warning";
            }}
          >
            {t("navigation.warnings")}{" "}
            <span className="counter">{warningData.length}</span>
          </NavLink>
        </li>
        }
        {documentData.length > 0 &&
        <li>
          <NavLink
            to={`${id}/?tab=documents`}
            isActive={() => {
              return tabName === "documents";
            }}
          >
            {t("navigation.other")}{" "}
            <span className="counter">{documentData.length}</span>
          </NavLink>
        </li>
        }
      </ul>
    </nav>
  );
};

export default Navigation;
