import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import Main from "./Pages/Main/Main";
import Error from "./Pages/Error/Error";
import Health from "./Pages/Health/Health";

const AppRouter: React.FC = () => (
  <Router>
    <Switch>
      <Route path="/health">
        <Health />
      </Route>
      <Route path="/error">
        <Error />
      </Route>
      <Route
        path="/:id"
        component={() => (
          <>
            <Navigation />
            <Main />
          </>
        )}
      />

      <Route>
        <p style={{ padding: "20px" }}>Patient ID missing</p>
      </Route>
    </Switch>
  </Router>
);
export default AppRouter;
